<template>
  <Leadgenerator
      @submit="createLeadgenerator"
      :processing="processing"
      btn-text="Funnel veröffentlichen"/>
</template>

<script>
import axios from 'axios'
import Leadgenerator from "@/views/base/leadgenerator/Leadgenerator";

export default {
  name: 'LeadgeneratorEdit',
  components: { Leadgenerator },
  data() {
    return {
      processing: false,
      leadgenerator: null
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.params.saved) {
      next()
      return
    }
    this.$modal.showModal(
        'default',
        'Editor wirklich schließen?',
        [
          `Es gibt noch Änderungen im Editor, die nicht gespeichert wurden und mit dieser Aktion verloren gehen.`
        ],
        () => {
          next()
        },
        () => {
          next(false)
        },
        { btnCancel: 'Abbrechen', btnConfirm: 'Trotzdem schließen', btnConfirmColor: 'danger' }
    )
  },
  methods: {
    createLeadgenerator(data) {
      this.processing = true
      data.contentJson = JSON.stringify(data.contentJson)
      axios.post('/leadgenerator/', data)
          .then(() => {
            this.$router.push({name: 'LeadgeneratorList', params: { saved: '1' }})
            this.$toaster.makeToast('Erfolgreich!', "<b>Funnel wurde erfolgreich erstellt</b>")
          })
          .catch(err => {
            if (err.response) {
              this.$toaster.makeToast('Oops!', '<b>' + err.response.data.code + '</b><br>' + err.response.data.message)
            } else {
              this.$toaster.makeToast('Fehler!', err.message)
            }
          })
          .finally(() => {
            this.processing = false
          })
    }
  }
}
</script>
